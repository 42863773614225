// export const ApiList = 'http://18.210.63.15:8006';
// export const ApiFileSend = 'http://18.210.63.15:9003'; //dcm file
// export const ApiPana = 'http://18.210.63.15:9001';
// export const ApiSideview = 'http://18.210.63.15:9002';
// export const Api3d = 'http://18.210.63.15:9000';
// export const ApiMetaFileGetter = 'http://18.210.63.15:9004';
// export const ApiDI = 'http://18.210.63.15:9005';

//live Setup
// export const Api = 'http://59.103.158.165';
// export const Base = 'http://59.103.158.165:3000';
// export const ApiList = 'http://59.103.158.165:3001';
// export const ApiFileSend = 'http://59.103.158.165:8333'; //dcm file
// export const ApiPana = 'http://59.103.158.165:8001';
// export const ApiSideview = 'http://59.103.158.165:8002';
// export const Api3d = 'http://59.103.158.165:9000';
// export const ApiMetaFileGetter = 'http://59.103.158.165:9004';
// export const ApiDI = 'http://59.103.158.165:9005';
//LIVE IP 59.103.158.165

export const Api = 'https://dentistviewer-api-test.icenna.com';
export const Base = 'https://dentistviewer-api-test.icenna.com';
export const ApiList = 'https://dentistviewer-api-test.icenna.com';
export const ApiFileSend = 'https://dentistviewer-api-test.icenna.com'; //dcm file
export const ApiPana = 'https://dentistviewer-api-test.icenna.com';
export const ApiSideview = 'https://dentistviewer-api-test.icenna.com';
export const Api3d = 'https://dentistviewer-api-test.icenna.com';
export const ApiMetaFileGetter = 'https://dentistviewer-api-test.icenna.com';
export const ApiDI = 'http://144.24.211.102:8080';

//local API's for Home
/* export const Api = 'http://192.168.100.11';
export const Base = 'http://192.168.0.115:3000';
export const ApiList = 'http://192.168.0.115:9008';
export const ApiFileSend = 'http://192.168.0.115:8003'; //dcm file
export const ApiPana = 'http://192.168.0.115:8001';
export const ApiSideview = 'http://192.168.0.115:9002';
export const Api3d = 'http://192.168.0.115:9000';
export const ApiMetaFileGetter = 'http://192.168.0.115:9004';
export const ApiDI = 'http://192.168.0.115:9005'; */

//local setup for office
/*export const Api = 'http://192.168.100.24';
export const Base = 'http://192.168.100.24:3000';
export const ApiList = 'http://192.168.100.24:3001';
export const ApiFileSend = 'http://192.168.100.24:8003'; //dcm file
export const ApiPana = 'http://192.168.100.24:8001';
export const ApiSideview = 'http://192.168.100.24:9002';
export const Api3d = 'http://192.168.100.24:9000';
export const ApiMetaFileGetter = 'http://192.168.100.24:9004';
export const ApiDI = 'http://192.168.100.24:9005';*/
